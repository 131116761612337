import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import config from "./config";


const ProfilModal = ({ open, handleClose, clientData, onSuccess }) => {
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    // Utilisation de useEffect pour initialiser les champs avec les données du client
    useEffect(() => {
        if (clientData) {
            setEmail(clientData.email || '');  // Sécurité supplémentaire si clientData.email est null ou undefined
            setMotDePasse('');  // Optionnel, ne pas préremplir le mot de passe pour des raisons de sécurité
        }
    }, [clientData]);

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            console.log('config.apiUrl:', config.apiUrl);
            console.log('URL utilisée pour la requête PUT :', `${config.apiUrl}api/client/profile`);
            const response = await axios.put(`${config.apiUrl}/api/client/profile`, {
                email,
                motDePasse,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            onSuccess(response.data);  // Appelle onSuccess pour mettre à jour l'interface utilisateur
            handleClose();  // Ferme le modal après la mise à jour
        } catch (error) {
            console.error('Erreur lors de la mise à jour du profil', error);
            setErrorMessage('Erreur lors de la mise à jour du profil');
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description"
        >
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2
            }}>
                <Typography id="modal-profile-title" variant="h6" component="h2">
                    Modifier votre profil
                </Typography>

                {errorMessage && (
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>
                )}

                <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Mot de passe"
                    type="password"
                    margin="normal"
                    value={motDePasse}
                    onChange={(e) => setMotDePasse(e.target.value)}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button onClick={handleClose} variant="outlined">Annuler</Button>
                    <Button onClick={handleSave} variant="contained" color="primary">Sauvegarder</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProfilModal;
