import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper, Stack } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns'; // Utilisez date-fns pour formatter les dates
import config from "./config";


const FormProjet = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [statut] = useState('en cours');
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    // Fonction pour formater la date en "dd-MM-yy HH:mm:ss"
    const formatDateForBackend = (date) => {
        if (!date) return null;
        return format(new Date(date), 'dd-MM-yy HH:mm:ss'); // Utilisation de date-fns pour formater
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedDateDebut = formatDateForBackend(dateDebut);
        const formattedDateFin = formatDateForBackend(dateFin);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.apiUrl}/api/projets`, {
                nom,
                description,
                statut,
                dateDebut: formattedDateDebut,
                dateFin: formattedDateFin
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201) {
                onSuccess(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de la création du projet', error);
            setErrorMessage('Erreur lors de la création du projet');
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}
                <TextField
                    label="Nom du Projet"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <TextField
                    label="Date de début"
                    type="datetime-local"
                    value={dateDebut}
                    onChange={(e) => setDateDebut(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Date de fin"
                    type="datetime-local"
                    value={dateFin}
                    onChange={(e) => setDateFin(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default FormProjet;
