import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Paper, Stack } from '@mui/material';
import axios from 'axios';
import config from "./config";

const FormClient = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('ROLE_CLIENT'); // Valeur par défaut
    const [societeId, setSocieteId] = useState(''); // ID de la société sélectionnée
    const [societes, setSocietes] = useState([]); // Pour stocker les sociétés disponibles
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const loadSocietes = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/api/societes`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setSocietes(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Erreur lors du chargement des sociétés', error);
                setSocietes([]);
            }
        };

        loadSocietes();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.apiUrl}/api/client/enregistrer`, {
                email,
                role,
                nom,
                societe: societeId ? { id: societeId } : null, // Transmettre l'ID de la société sélectionnée
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                onSuccess(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de la création du client', error);
            setErrorMessage('Erreur lors de la création du client');
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title || 'Créer un Client'}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                <TextField
                    label="Nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />

                <TextField
                    select
                    label="Rôle"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                >
                    <MenuItem value="ROLE_CLIENT">Client</MenuItem>
                    <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                </TextField>

                <TextField
                    select
                    label="Société"
                    value={societeId || ''}
                    onChange={(e) => setSocieteId(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    helperText="Sélectionner une société ou laisser vide"
                >
                    <MenuItem value="">Aucune société</MenuItem>
                    {Array.isArray(societes) && societes.length > 0 ? (
                        societes.map((societe) => (
                            <MenuItem key={societe.id} value={societe.id}>
                                {societe.nom}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Aucune société disponible</MenuItem>
                    )}
                </TextField>

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default FormClient;
