import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper, Stack, MenuItem } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import config from "./config";

const FormTache = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [categorie, setCategorie] = useState('AUTRE');
    const [projetId, setProjetId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState('');

    const formatDateForBackend = (date) => {
        if (!date) return null;
        return format(new Date(date), 'dd-MM-yy HH:mm:ss'); // Format des dates
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedDateDebut = formatDateForBackend(dateDebut);
        const formattedDateFin = formatDateForBackend(dateFin);

        try {
            const token = localStorage.getItem('token');

            if (!token) {
                throw new Error('Token JWT manquant ou invalide.');
            }

            // Envoi de la requête POST
            const response = await axios.post(`${config.apiUrl}/api/taches`, {
                nom,
                description,
                categorie,
                projetId,
                dateDebut: formattedDateDebut,
                dateFin: formattedDateFin,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Réponse du serveur:', response.data);
            onSuccess(); // Appeler une fonction en cas de succès
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || 'Erreur du serveur.');
            } else if (error.request) {
                setErrorMessage('Aucune réponse du serveur.');
            } else {
                setErrorMessage(error.message);
            }
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}
                <TextField
                    select
                    label="Catégorie"
                    value={categorie}
                    onChange={(e) => setCategorie(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    <MenuItem value="INCIDENT">Incident</MenuItem>
                    <MenuItem value="PROJET">Projet</MenuItem>
                    <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
                    <MenuItem value="AUTRE">Autre</MenuItem>
                </TextField>
                <TextField
                    label="Nom de la Tâche"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <TextField
                    label="Projet ID (Optionnel)"
                    value={projetId}
                    onChange={(e) => setProjetId(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Date de début"
                    type="datetime-local"
                    value={dateDebut}
                    onChange={(e) => setDateDebut(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Date de fin"
                    type="datetime-local"
                    value={dateFin}
                    onChange={(e) => setDateFin(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default FormTache;
