import React, { useState } from 'react';
import axios from 'axios';
import './LoginModal.css';
import { useNavigate } from 'react-router-dom';
import config from "./config";

function LoginModal({ isOpen, onClose, isDarkMode }) {
    console.log('LoginModal is rendering');
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    if (!isOpen) {
        return null; // Si isOpen est faux, ne pas afficher le modal
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${config.apiUrl}/api/auth/login`, {
                email: email,  // Utilisez les variables contenant l'email et le mot de passe
                motDePasse: motDePasse
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const token = response.data.token;
            const role = response.data.role;  // Utiliser directement le rôle renvoyé par l'API

            console.log("Rôle retourné par l'API :", role);  // Vérifie que le rôle est bien retourné

            // Stocker le token et le rôle sans ajout de préfixe dans localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);  // Utiliser le rôle tel qu'il est

            console.log("Rôle stocké dans localStorage :", localStorage.getItem('role'));  // Vérifie que le rôle est bien stocké

            // Redirection vers /user-dashboard, la gestion des rôles sera faite dans UserDashboard
            navigate('/user-dashboard');

            onClose();

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Invalid login credentials. Please try again.');
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
            console.error('Login error:', error);
        }
    };



    return (
        <div className="modal-overlay">
            <div className={`modal-content ${isDarkMode ? 'modal-dark' : 'modal-light'}`}>
                <button className="modal-close" onClick={onClose}>X</button>
                <form onSubmit={handleSubmit} className="modal-form">
                    <h2 className="modal-title">Connexion</h2>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                    <div className="input-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="input-group">
                        <label>Mot de passe</label>
                        <input
                            type="password"
                            value={motDePasse}
                            onChange={(e) => setMotDePasse(e.target.value)}
                            required
                            className="modal-input"
                        />
                    </div>
                    <button type="submit" className="modal-btn">Se connecter</button>
                </form>
            </div>
        </div>
    );
}

export default LoginModal;
