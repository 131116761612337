import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './Home';
import LoginModal from './LoginModal';
import UserDashboard from './UserDashboard';  // On utilise le même UserDashboard pour tous les rôles
import { ThemeProvider } from './ThemeContext';
import PrivateRoute from "./PrivateRoute";
import SetPassword from "./SetPassword";

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [role, setRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // État de chargement

    useEffect(() => {
        const roleFromStorage = localStorage.getItem('role');
        console.log("Rôle récupéré dans App.js :", roleFromStorage);
        setRole(roleFromStorage);
        setIsLoading(false); // Une fois le rôle récupéré, on arrête le "loading"
    }, []);

    /**
     * Composant PrivateRoute pour protéger les routes basées sur les rôles
     */
    const PrivateRoute = ({ children, allowedRoles }) => {
        const userRole = localStorage.getItem('role');
        console.log("Rôle de l'utilisateur :", userRole);

        // Si aucun rôle n'est trouvé ou si le rôle de l'utilisateur ne correspond pas à ceux autorisés
        if (!userRole || !allowedRoles.includes(userRole)) {
            console.warn("Accès refusé : redirection vers la page d'accueil");
            return <Navigate to="/" />;  // Redirige vers la page d'accueil
        }

        return children;  // Si le rôle est correct, on rend les enfants du composant (ici, le tableau de bord)
    };

    const handleOpenModal = () => {
        console.log("Modal is being opened");
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        console.log("Modal is being closed");
        setIsModalOpen(false);
    };

    if (isLoading) {
        return <div>Loading...</div>; // Affiche un écran de chargement pendant que le rôle est récupéré
    }

    return (
        <ThemeProvider>
            <Router>
                <div className="App">
                    <Routes>
                        {/* Accueil */}
                        <Route path="/" element={<HomePage onLoginClick={handleOpenModal} />} />
                        <Route path="/set-password" element={<SetPassword />} />

                        {/* Tableau de bord utilisateur pour admin et client */}
                        <Route
                            path="/user-dashboard"
                            element={
                                <PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_CLIENT']}>
                                    <UserDashboard role={role} />  {/* Passer le rôle au UserDashboard */}
                                </PrivateRoute>
                            }
                        />
                    </Routes>

                    <LoginModal isOpen={isModalOpen} onClose={handleCloseModal} isDarkMode={false} />
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
