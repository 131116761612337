// Importations nécessaires
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import config from './config';
import {
    Divider,
    IconButton,
    CircularProgress,
    Box,
    List,
    ListItem,
    ListItemIcon,
    Collapse,
    ListItemText,
    Typography,
    Drawer,
    Grid,
    Card,
    CardContent,
    Alert,
    Snackbar,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import {
    AccountCircle,
    Folder,
    Assignment,
    BugReport,
    Logout,
    MailOutline,
    Settings,
    ExpandLess,
    ExpandMore,
    AddCircleOutline,
    Edit,
    HourglassEmpty,
    Delete,
    Lock,
    CheckCircle,
    ChevronRight,
    ChevronLeft,
    Close as CloseIcon, Visibility,
} from '@mui/icons-material';

import logoDark from './assets/logo-dark.png';
import './SideBar.css';
import './UserDashboard.css';
import FormProjet from './FormProjet';
import FormTache from './FormTache';
import FormTicket from './FormTicket';
import FormSociete from './FormSociete';
import FormClient from './FormClient';
import ProfilModal from './ProfilModal';
import ReportModal from './ReportModal';
import CheckIcon from '@mui/icons-material/Check';

// Composant pour la barre latérale (Sidebar)
const Sidebar = ({
                     isOpen,
                     handleDrawerToggle,
                     openProjets,
                     handleProjetsClick,
                     openTaches,
                     handleTachesClick,
                     openTickets,
                     handleTicketsClick,
                     openAdmin,
                     handleAdminClick,
                     role,
                     handleCreateClick,
                     setActiveData,
                 }) => (
    <Drawer
        variant="permanent"
        open={isOpen}
        sx={{
            width: isOpen ? 240 : 60,
            transition: 'width 0.3s ease',
            '& .MuiDrawer-paper': {
                width: isOpen ? 240 : 60,
                transition: 'width 0.3s ease',
                backgroundColor: '#2c5364',
                color: 'white',
                overflowX: 'hidden',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
                borderRight: isOpen ? '1px solid rgba(255, 255, 255, 0.2)' : 'none',
            },
        }}
    >
        {/* Bouton pour ouvrir/fermer la barre latérale */}
        <IconButton
            onClick={handleDrawerToggle}
            sx={{ alignSelf: isOpen ? 'flex-end' : 'center', m: 1 }}
        >
            {isOpen ? (
                <ChevronLeft sx={{ color: 'white' }} />
            ) : (
                <ChevronRight sx={{ color: 'white' }} />
            )}
        </IconButton>

        {/* Logo */}
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 3,
                width: '100%',
            }}
        >
            {isOpen && (
                <img
                    alt="Logo"
                    src={logoDark}
                    style={{ width: '70%', height: 'auto' }}
                />
            )}
        </Box>

        <List>
            {/* Section Projets */}
            <ListItem
                button
                onClick={() => {
                    handleProjetsClick();
                    setActiveData('projets');
                }}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#203a43',
                        transition: 'background-color 0.3s ease',
                    },
                }}
            >
                <ListItemIcon>
                    <Folder sx={{ color: 'white', fontSize: 30 }} />
                </ListItemIcon>
                {isOpen && (
                    <ListItemText
                        primary={
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                            >
                                Projets
                            </Typography>
                        }
                    />
                )}
                {isOpen &&
                    (openProjets ? (
                        <ExpandLess sx={{ color: '#ffca28' }} />
                    ) : (
                        <ExpandMore sx={{ color: '#ffca28' }} />
                    ))}
            </ListItem>

            <Collapse in={openProjets} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        onClick={() => handleCreateClick('projets')}
                        sx={{
                            pl: 4,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#203a43',
                                transition: 'background-color 0.3s ease',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleOutline sx={{ color: '#ffca28' }} />
                        </ListItemIcon>
                        {isOpen && <ListItemText primary="Créer Projet" />}
                    </ListItem>
                </List>
            </Collapse>

            {/* Section Tâches */}
            <ListItem
                button
                onClick={() => {
                    handleTachesClick();
                    setActiveData('taches');
                }}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#203a43',
                        transition: 'background-color 0.3s ease',
                    },
                }}
            >
                <ListItemIcon>
                    <Assignment sx={{ color: 'white', fontSize: 30 }} />
                </ListItemIcon>
                {isOpen && (
                    <ListItemText
                        primary={
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                            >
                                Tâches
                            </Typography>
                        }
                    />
                )}
                {isOpen &&
                    (openTaches ? (
                        <ExpandLess sx={{ color: '#ffca28' }} />
                    ) : (
                        <ExpandMore sx={{ color: '#ffca28' }} />
                    ))}
            </ListItem>

            <Collapse in={openTaches} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        onClick={() => handleCreateClick('taches')}
                        sx={{
                            pl: 4,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#203a43',
                                transition: 'background-color 0.3s ease',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleOutline sx={{ color: '#ffca28' }} />
                        </ListItemIcon>
                        {isOpen && <ListItemText primary="Créer Tâche" />}
                    </ListItem>
                </List>
            </Collapse>

            {/* Section Tickets */}
            <ListItem
                button
                onClick={() => {
                    handleTicketsClick();
                    setActiveData('tickets');
                }}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#203a43',
                        transition: 'background-color 0.3s ease',
                    },
                }}
            >
                <ListItemIcon>
                    <BugReport sx={{ color: 'white', fontSize: 30 }} />
                </ListItemIcon>
                {isOpen && (
                    <ListItemText
                        primary={
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                            >
                                Tickets
                            </Typography>
                        }
                    />
                )}
                {isOpen &&
                    (openTickets ? (
                        <ExpandLess sx={{ color: '#ffca28' }} />
                    ) : (
                        <ExpandMore sx={{ color: '#ffca28' }} />
                    ))}
            </ListItem>

            <Collapse in={openTickets} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        onClick={() => handleCreateClick('tickets')}
                        sx={{
                            pl: 4,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#203a43',
                                transition: 'background-color 0.3s ease',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleOutline sx={{ color: '#ffca28' }} />
                        </ListItemIcon>
                        {isOpen && <ListItemText primary="Créer Ticket" />}
                    </ListItem>
                </List>
            </Collapse>

            {/* Section Administration (uniquement pour les admins) */}
            {role === 'ROLE_ADMIN' && (
                <>
                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', my: 2 }} />

                    <ListItem
                        button
                        onClick={handleAdminClick}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#203a43',
                                transition: 'background-color 0.3s ease',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <Settings sx={{ color: 'white', fontSize: 30 }} />
                        </ListItemIcon>
                        {isOpen && (
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                    >
                                        Administration
                                    </Typography>
                                }
                            />
                        )}
                        {isOpen &&
                            (openAdmin ? (
                                <ExpandLess sx={{ color: '#ffca28' }} />
                            ) : (
                                <ExpandMore sx={{ color: '#ffca28' }} />
                            ))}
                    </ListItem>

                    <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                onClick={() => handleCreateClick('creer-societe')}
                                sx={{
                                    pl: 4,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#203a43',
                                        transition: 'background-color 0.3s ease',
                                    },
                                }}
                            >
                                <ListItemIcon>
                                    <AddCircleOutline sx={{ color: '#ffca28' }} />
                                </ListItemIcon>
                                {isOpen && <ListItemText primary="Créer Société" />}
                            </ListItem>

                            <ListItem
                                button
                                onClick={() => handleCreateClick('creer-client')}
                                sx={{
                                    pl: 4,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#203a43',
                                        transition: 'background-color 0.3s ease',
                                    },
                                }}
                            >
                                <ListItemIcon>
                                    <AddCircleOutline sx={{ color: '#ffca28' }} />
                                </ListItemIcon>
                                {isOpen && <ListItemText primary="Créer Client" />}
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            )}
        </List>
    </Drawer>
);

// Composant principal
const UserDashboard = () => {
    // États
    const [isOpen, setIsOpen] = useState(true);
    const [projets, setProjets] = useState([]);
    const [taches, setTaches] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [activeData, setActiveData] = useState('projets');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [formType, setFormType] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [newProjectId, setNewProjectId] = useState(null);
    const [dateTime, setDateTime] = useState(new Date());
    const [openProjets, setOpenProjets] = useState(false);
    const [openTaches, setOpenTaches] = useState(false);
    const [openTickets, setOpenTickets] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showProfileForm, setShowProfileForm] = useState(false);
    const [clientData, setClientData] = useState(null);
    const [role, setRole] = useState(localStorage.getItem('role'));
    const [openAdmin, setOpenAdmin] = useState(false);

    // États pour la sidebar
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [editedTicket, setEditedTicket] = useState(null);

    // Fonctions utilitaires
    const formatStatus = (status) => {
        switch (status) {
            case 'EN_ATTENTE':
                return 'En attente';
            case 'PRIS_EN_COMPTE':
                return 'Pris en compte';
            case 'EN_COURS':
                return 'En cours';
            case 'TRAITE':
                return 'Traité';
            case 'CLOTURE':
                return 'Clôturé';
            case 'SUPPRIME':
                return 'Supprimé';
            default:
                return status;
        }
    };

    const getProgressValue = (count) => (count > 0 ? Math.min((count / 10) * 100, 100) : 0);

    const isCreator = (item) => clientData && item.client && clientData.email === item.client.email;

    // Gestion des états pour les sections
    const handleDrawerToggle = () => setIsOpen(!isOpen);
    const handleProjetsClick = () => setOpenProjets(!openProjets);
    const handleTachesClick = () => setOpenTaches(!openTaches);
    const handleTicketsClick = () => setOpenTickets(!openTickets);
    const handleAdminClick = () => setOpenAdmin(!openAdmin);

    // Gestion des formulaires
    const handleCreateClick = (type) => {
        setFormType(type);
        setIsFormVisible(true);
    };

    // Ouvrir la sidebar pour les tickets
    const handleModifyClick = (row) => {
        if (activeData === 'tickets') {
            setSelectedTicket(row);
            setEditedTicket({ ...row }); // Cloner les informations du ticket pour édition
            setIsSidebarOpen(true);
        } else {
            setFormType(activeData);
            setIsFormVisible(true);
            setSelectedItem(row);
        }
    };

    const handleCloseForm = () => {
        setIsFormVisible(false);
        setSelectedItem(null);
    };

    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
        setSelectedTicket(null);
        setEditedTicket(null);
    };

    // Gestion des changements dans les champs de la sidebar
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedTicket({ ...editedTicket, [name]: value });
    };

    // Enregistrer les modifications du ticket
    const handleSaveChanges = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(
                `${config.apiUrl}/api/tickets/${editedTicket.id}`,
                editedTicket,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.status === 200) {
                // Mise à jour de l'état local des tickets
                setTickets((prevTickets) =>
                    prevTickets.map((ticket) =>
                        ticket.id === editedTicket.id ? editedTicket : ticket
                    )
                );

                // Envoi de l'email après la mise à jour du statut
                try {
                    await axios.post(`${config.apiUrl}/api/send-email`, {
                        ticketId: editedTicket.id,
                        newStatus: editedTicket.statut
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    console.log(`Email envoyé avec succès pour le ticket ${editedTicket.id} avec le statut ${editedTicket.statut}`);
                } catch (emailError) {
                    console.error('Erreur lors de l\'envoi de l\'email', emailError);
                }

                handleCloseSidebar(); // Fermer la sidebar après la sauvegarde
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du ticket', error);
        }
    };


    const handleStatusChange = async (id, newStatus, type) => {
        if (!id || !newStatus || !type) {
            console.error("Les paramètres 'id', 'newStatus', ou 'type' sont manquants.");
            return;
        }

        const urlMap = {
            'ticket': `${config.apiUrl}/api/tickets/${id}/status`,
            'tache': `${config.apiUrl}/api/taches/${id}/status`,
            'projet': `${config.apiUrl}/api/projets/${id}/status`
        };

        const url = urlMap[type];

        if (!url) {
            console.error(`Type d'objet invalide : ${type}`);
            return;
        }

        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(url, null, {
                params: { newStatus: newStatus },
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                // Mise à jour immédiate de l'état local pour refléter le changement de statut
                if (type === 'ticket') {
                    setTickets((prevItems) =>
                        prevItems.map((ticket) =>
                            ticket.id === id ? { ...ticket, statut: newStatus } : ticket
                        )
                    );
                } else if (type === 'tache') {
                    setTaches((prevItems) =>
                        prevItems.map((tache) =>
                            tache.id === id ? { ...tache, statut: newStatus } : tache
                        )
                    );
                } else if (type === 'projet') {
                    setProjets((prevItems) =>
                        prevItems.map((projet) =>
                            projet.id === id ? { ...projet, statut: newStatus } : projet
                        )
                    );
                }
                console.log(`Statut de ${type} mis à jour avec succès`);
            }
        } catch (error) {
            console.error(`Erreur lors de la mise à jour du statut pour ${type}`, error);
        }
    };

    const handleClotureClick = async (row) => {
        let apiUrl = '';
        const token = localStorage.getItem('token');

        // Vérifie si le type de données est projet, tâche ou ticket et ajuste l'URL de l'API en conséquence
        if (activeData === 'projets') {
            apiUrl = `${config.apiUrl}/api/projets/${row.id}/cloture`;
        } else if (activeData === 'taches') {
            apiUrl = `${config.apiUrl}/api/taches/${row.id}/cloture`;
        } else if (activeData === 'tickets') {
            apiUrl = `${config.apiUrl}/api/tickets/${row.id}/cloture`;
        }

        try {
            const response = await axios.put(apiUrl, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(`${activeData.slice(0, -1)} clôturé`, response.data);

            // Mettre à jour l'état local après la clôture
            if (activeData === 'projets') {
                setProjets((prevProjets) =>
                    prevProjets.map((projet) =>
                        projet.id === row.id
                            ? { ...projet, statut: 'CLOTURE', dateCloture: new Date().toLocaleString('fr-FR') }
                            : projet
                    )
                );
            } else if (activeData === 'taches') {
                setTaches((prevTaches) =>
                    prevTaches.map((tache) =>
                        tache.id === row.id
                            ? { ...tache, statut: 'CLOTURE', dateCloture: new Date().toLocaleString('fr-FR') }
                            : tache
                    )
                );
            } else if (activeData === 'tickets') {
                setTickets((prevTickets) =>
                    prevTickets.map((ticket) =>
                        ticket.id === row.id
                            ? { ...ticket, statut: 'CLOTURE', dateFermeture: new Date().toLocaleString('fr-FR') }
                            : ticket
                    )
                );
            }
        } catch (error) {
            console.error(`Erreur lors de la clôture du ${activeData.slice(0, -1)}`, error);
        }
    };

    const handleTreatedClick = (ticket) => {
        setSelectedTicket(ticket); // Enregistrer le ticket sélectionné
        setIsReportModalOpen(true); // Ouvrir le modal
    };

    const handleReportSubmit = async (report) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(
                `${config.apiUrl}/api/tickets/${selectedTicket.id}/status`,
                { report },
                {
                    params: { newStatus: 'TRAITE' },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.status === 200) {
                // Mettre à jour les tickets ou rafraîchir les données
                fetchData();
                setIsReportModalOpen(false); // Fermer le modal après soumission
            }
        } catch (error) {
            console.error('Erreur lors de la soumission du rapport', error);
        }
    };

    const handleDeleteClick = async (row) => {
        let apiUrl = '';
        const token = localStorage.getItem('token');

        // Vérifie le type de données actif et ajuste l'URL de l'API
        if (activeData === 'tickets') {
            apiUrl = `${config.apiUrl}/api/tickets/${row.id}/supprimer`;
        } else if (activeData === 'taches') {
            apiUrl = `${config.apiUrl}/api/taches/${row.id}/supprimer`;
        } else if (activeData === 'projets') {
            apiUrl = `${config.apiUrl}/api/projets/${row.id}/supprimer`;
        }

        try {
            const response = await axios.put(apiUrl, null, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200) {
                // Mise à jour de l'état local après la suppression
                if (activeData === 'tickets') {
                    setTickets((prevTickets) =>
                        prevTickets.map((ticket) =>
                            ticket.id === row.id ? { ...ticket, statut: 'SUPPRIME' } : ticket
                        )
                    );
                } else if (activeData === 'taches') {
                    setTaches((prevTaches) =>
                        prevTaches.map((tache) =>
                            tache.id === row.id ? { ...tache, statut: 'SUPPRIME' } : tache
                        )
                    );
                } else if (activeData === 'projets') {
                    setProjets((prevProjets) =>
                        prevProjets.map((projet) =>
                            projet.id === row.id ? { ...projet, statut: 'SUPPRIME' } : projet
                        )
                    );
                }
            }
        } catch (error) {
            console.error('Erreur lors de la suppression', error);
        }
    };

    // Gestion du profil
    const handleProfileClick = () => setShowProfileForm(true);
    const handleCloseProfile = () => setShowProfileForm(false);

    // Déconnexion
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.href = '/';
    };

    // Gestion des données
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        try {
            const [projetsResponse, tachesResponse, ticketsResponse] = await Promise.all([
                axios.get(`${config.apiUrl}/api/projets`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/taches`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/tickets`, { headers: { Authorization: `Bearer ${token}` } }),
            ]);

            setProjets(projetsResponse.data);
            setTaches(tachesResponse.data);
            setTickets(ticketsResponse.data);
        } catch (err) {
            console.error('Erreur lors du chargement des données :', err);
        }
    };

    useEffect(() => {
        fetchData();

        // Charger les informations du client connecté
        const token = localStorage.getItem('token');
        axios
            .get(`${config.apiUrl}/api/client/profile`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setClientData(response.data);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération du profil du client', error);
            });

        // Mettre à jour l'heure en temps réel
        const timer = setInterval(() => setDateTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    // Colonnes pour les différents tableaux
    const columnsProjets = [
        { field: 'numeroProjet', headerName: 'ID Projet', width: 50, flex: 1 },
        { field: 'nom', headerName: 'Nom', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            width: 150,
            flex: 1,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span> // Utilise la fonction formatStatus ici
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            sortable: false,
            renderCell: (params) => {
                const isDeleted = params.row.statut === 'SUPPRIME';
                const isCloture = params.row.statut === 'CLOTURE';
                const role = localStorage.getItem('role'); // Récupère le rôle depuis le localStorage

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <IconButton
                            color="primary"
                            onClick={() => handleModifyClick(params.row)}
                            disabled={isDeleted || isCloture}
                        >
                            <Visibility />
                        </IconButton>

                        {role === 'ROLE_ADMIN' && (
                            <>
                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'PRIS_EN_COMPTE', 'projet')}
                                    disabled={params.row.statut !== 'EN_ATTENTE'}
                                >
                                    <HourglassEmpty />
                                </IconButton>

                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'EN_COURS', 'projet')}
                                    disabled={params.row.statut !== 'PRIS_EN_COMPTE'}
                                >
                                    <Visibility />
                                </IconButton>

                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'TRAITE', 'projet')}
                                    disabled={params.row.statut !== 'EN_COURS'}
                                >
                                    <CheckCircle />
                                </IconButton>
                            </>
                        )}

                        <IconButton
                            color="success"
                            onClick={() => handleClotureClick(params.row)}
                            disabled={isDeleted || isCloture}
                        >
                            <Lock />
                        </IconButton>

                        {role === 'ROLE_ADMIN' && (
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteClick(params.row)}
                                disabled={isDeleted || isCloture}
                            >
                                <Delete />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
    ];

    // Colonnes pour les tâches
    const columnsTaches = [
        { field: 'numeroTache', headerName: 'ID Tâche', width: 50, flex: 1 },
        { field: 'nom', headerName: 'Nom', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            width: 150,
            flex: 1,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span> // Utilise la fonction formatStatus ici
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            sortable: false,
            renderCell: (params) => {
                const isDeleted = params.row.statut === 'SUPPRIME';
                const isCloture = params.row.statut === 'CLOTURE';
                const role = localStorage.getItem('role'); // Récupère le rôle depuis le localStorage

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <IconButton
                            color="primary"
                            onClick={() => handleModifyClick(params.row)}
                            disabled={isDeleted || isCloture}
                        >
                            <Visibility />
                        </IconButton>

                        {role === 'ROLE_ADMIN' && (
                            <>
                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'PRIS_EN_COMPTE', 'tache')}
                                    disabled={params.row.statut !== 'EN_ATTENTE'}
                                >
                                    <HourglassEmpty />
                                </IconButton>

                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'EN_COURS', 'tache')}
                                    disabled={params.row.statut !== 'PRIS_EN_COMPTE'}
                                >
                                    <Visibility />
                                </IconButton>

                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'TRAITE', 'tache')}
                                    disabled={params.row.statut !== 'EN_COURS'}
                                >
                                    <CheckCircle />
                                </IconButton>
                            </>
                        )}

                        <IconButton
                            color="success"
                            onClick={() => handleClotureClick(params.row)}
                            disabled={isDeleted || isCloture}
                        >
                            <Lock />
                        </IconButton>

                        {role === 'ROLE_ADMIN' && (
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteClick(params.row)}
                                disabled={isDeleted || isCloture}
                            >
                                <Delete />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
    ];

    // Colonnes pour les tickets
    const columnsTickets = [
        { field: 'numeroTicket', headerName: 'ID Ticket', width: 50, flex: 1 },
        { field: 'dateOuverture', headerName: 'Date de Création', width: 150, flex: 1 },
        {
            field: 'client_id',  // Nom du champ retourné par l'API
            headerName: 'Créateur',
            width: 150,
            flex: 1,
        },
        { field: 'titre', headerName: 'Titre', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            width: 150,
            flex: 1,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span> // Utilise la fonction formatStatus ici
            )
        },
        { field: 'dateFermeture', headerName: 'Date de Clôture', width: 150, flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            sortable: false,
            renderCell: (params) => {
                const isDeleted = params.row.statut === 'SUPPRIME';
                const isCloture = params.row.statut === 'CLOTURE';
                const isTraite = params.row.statut === 'TRAITE';
                const role = localStorage.getItem('role'); // Récupère le rôle depuis le localStorage

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        {/* Icône pour voir ou modifier */}
                        <IconButton
                            color="primary"
                            onClick={() => handleModifyClick(params.row)}
                            disabled={isDeleted || (role === 'ROLE_ADMIN' && isCloture)}
                        >
                            <Visibility />
                        </IconButton>

                        {/* Actions spécifiques pour l'admin */}
                        {role === 'ROLE_ADMIN' && (
                            <>
                                {/* Icône pour changer le statut à "Pris en compte" */}
                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'PRIS_EN_COMPTE', 'ticket')}
                                    disabled={params.row.statut !== 'EN_ATTENTE'}
                                >
                                    <HourglassEmpty />
                                </IconButton>

                                {/* Icône pour changer le statut à "En cours" */}
                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'EN_COURS', 'ticket')}
                                    disabled={params.row.statut !== 'PRIS_EN_COMPTE'}
                                >
                                    <Edit />
                                </IconButton>

                                {/* Icône pour changer le statut à "Traité" */}
                                <IconButton
                                    onClick={() => handleStatusChange(params.row.id, 'TRAITE', 'ticket')}
                                    disabled={params.row.statut !== 'EN_COURS'}
                                >
                                    <CheckCircle />
                                </IconButton>
                            </>
                        )}

                        {/* Icône de Clôture : Active si le ticket est traité et non clôturé */}
                        <IconButton
                            onClick={() => handleTreatedClick(params.row)}
                            disabled={params.row.statut !== 'EN_COURS'}
                        >
                            <CheckCircle />
                        </IconButton>
                        <IconButton
                            color="success"
                            onClick={() => handleClotureClick(params.row)}
                            disabled={isDeleted || isCloture || (role === 'ROLE_CLIENT' && params.row.statut !== 'TRAITE')} // Vérifie si le statut est "TRAITE" pour le client
                        >
                            <Lock />
                        </IconButton>

                        {/* Icône pour supprimer (seulement pour l'admin) */}
                        {role === 'ROLE_ADMIN' && (
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteClick(params.row)}
                                disabled={isDeleted || isCloture}
                            >
                                <Delete />
                            </IconButton>
                        )}
                    </div>
                );
            },
        }
    ];

    // Fonctions pour obtenir les colonnes et les lignes actives
    const getActiveColumns = () => {
        switch (activeData) {
            case 'taches':
                return columnsTaches;
            case 'tickets':
                return columnsTickets;
            default:
                return columnsProjets;
        }
    };

    const getActiveRows = () => {
        switch (activeData) {
            case 'taches':
                return taches;
            case 'tickets':
                return tickets;
            default:
                return projets;
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {/* Barre latérale */}
            <Sidebar
                isOpen={isOpen}
                handleDrawerToggle={handleDrawerToggle}
                openProjets={openProjets}
                handleProjetsClick={handleProjetsClick}
                openTaches={openTaches}
                handleTachesClick={handleTachesClick}
                openTickets={openTickets}
                handleTicketsClick={handleTicketsClick}
                openAdmin={openAdmin}
                handleAdminClick={handleAdminClick}
                role={role}
                handleCreateClick={handleCreateClick}
                setActiveData={setActiveData}
            />

            {/* Contenu principal */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    padding: 3,
                    transition: 'width 0.3s ease',
                    width: `calc(100% - ${isOpen ? '240px' : '60px'})`,
                }}
            >
                {/* En-tête */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h4" component="h1">
                        Dashboard
                    </Typography>

                    {/* Notifications et actions utilisateur */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ mr: 2 }}>
                            {dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()}
                        </Typography>
                        <IconButton onClick={handleProfileClick}>
                            <AccountCircle />
                        </IconButton>
                        {/* Modal pour le profil */}
                        <ProfilModal
                            open={showProfileForm}
                            handleClose={handleCloseProfile}
                            clientData={clientData}
                            onSuccess={() => setShowProfileForm(false)}
                        />
                        <IconButton>
                            <MailOutline />
                        </IconButton>
                        <IconButton>
                            <Settings />
                        </IconButton>
                        <IconButton onClick={handleLogout}>
                            <Logout />
                        </IconButton>
                    </Box>
                </Box>

                {/* Cartes de statistiques */}
                <Grid container spacing={2}>
                    {/* Carte Projets */}
                    <Grid item xs={4}>
                        <Card
                            onClick={() => setActiveData('projets')}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: '#f4f6f9',
                                borderRadius: '16px',
                                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.15)',
                                '&:hover': {
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                                },
                            }}
                        >
                            <CardContent
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Box textAlign="left">
                                    <Typography variant="h6" fontWeight="bold" sx={{ color: '#333' }}>
                                        Projets
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgressValue(projets.length)}
                                        size={60}
                                        thickness={5}
                                        sx={{ color: '#1976d2' }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{ position: 'absolute', color: '#333', fontWeight: 'bold' }}
                                    >
                                        {projets.length}
                                    </Typography>
                                </Box>

                                <Box textAlign="right">
                                    <Typography variant="body2" color="textSecondary">
                                        Ouverts: {projets.filter((t) => t.statut === 'En attente').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Clôturés: {projets.filter((t) => t.statut === 'Clôturé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Supprimés: {projets.filter((t) => t.statut === 'Supprimé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Total: {projets.length}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Carte Tâches */}
                    <Grid item xs={4}>
                        <Card
                            onClick={() => setActiveData('taches')}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: '#f4f6f9',
                                borderRadius: '16px',
                                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.15)',
                                '&:hover': {
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                                },
                            }}
                        >
                            <CardContent
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Box textAlign="left">
                                    <Typography variant="h6" fontWeight="bold" sx={{ color: '#333' }}>
                                        Tâches
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgressValue(taches.length)}
                                        size={60}
                                        thickness={5}
                                        sx={{ color: '#1976d2' }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{ position: 'absolute', color: '#333', fontWeight: 'bold' }}
                                    >
                                        {taches.length}
                                    </Typography>
                                </Box>

                                <Box textAlign="right">
                                    <Typography variant="body2" color="textSecondary">
                                        Ouverts: {taches.filter((t) => t.statut === 'En attente').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Clôturés: {taches.filter((t) => t.statut === 'Clôturé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Supprimés: {taches.filter((t) => t.statut === 'Supprimé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Total: {taches.length}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Carte Tickets */}
                    <Grid item xs={4}>
                        <Card
                            onClick={() => setActiveData('tickets')}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: '#f4f6f9',
                                borderRadius: '16px',
                                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.15)',
                                '&:hover': {
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                                },
                            }}
                        >
                            <CardContent
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Box textAlign="left">
                                    <Typography variant="h6" fontWeight="bold" sx={{ color: '#333' }}>
                                        Tickets
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgressValue(tickets.length)}
                                        size={60}
                                        thickness={5}
                                        sx={{ color: '#1976d2' }}
                                    />
                                    <Typography
                                        variant="h5"
                                        sx={{ position: 'absolute', color: '#333', fontWeight: 'bold' }}
                                    >
                                        {tickets.length}
                                    </Typography>
                                </Box>

                                <Box textAlign="right">
                                    <Typography variant="body2" color="textSecondary">
                                        Ouverts: {tickets.filter((t) => t.statut === 'En attente').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Clôturés: {tickets.filter((t) => t.statut === 'Clôturé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Supprimés: {tickets.filter((t) => t.statut === 'Supprimé').length}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Total: {tickets.length}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Tableau des données */}
                <Box sx={{ flexGrow: 1, mt: 3 }}>
                    {isFormVisible ? (
                        // Affichage des formulaires
                        <>
                            {formType === 'projets' && (
                                <FormProjet
                                    title={selectedItem ? 'Modifier le Projet' : 'Créer un Projet'}
                                    onClose={handleCloseForm}
                                    projetData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'taches' && (
                                <FormTache
                                    title={selectedItem ? 'Modifier la Tâche' : 'Créer une Tâche'}
                                    onClose={handleCloseForm}
                                    tacheData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'tickets' && (
                                <FormTicket
                                    title={selectedItem ? 'Modifier le Ticket' : 'Créer un Ticket'}
                                    onClose={handleCloseForm}
                                    ticketData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'creer-societe' && (
                                <FormSociete
                                    title="Créer une Société"
                                    onClose={handleCloseForm}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'creer-client' && (
                                <FormClient
                                    title="Créer un Client"
                                    onClose={handleCloseForm}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            <ReportModal
                                open={isReportModalOpen}
                                handleClose={() => setIsReportModalOpen(false)}
                                onSubmit={handleReportSubmit}
                            />
                        </>
                    ) : (
                        // Affichage du tableau de données
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={getActiveRows().map((row) => ({
                                    ...row,
                                    className: row.statut === 'SUPPRIME' ? 'deleted-row' : '',
                                }))}
                                columns={getActiveColumns()}
                                pageSize={10}
                                rowsPerPageOptions={[5, 10, 20]}
                                checkboxSelection
                                disableSelectionOnClick
                                sortingOrder={['desc', 'asc']}
                                initialState={{
                                    sorting: {
                                        sortModel: activeData === 'tickets'
                                            ? [{ field: 'dateOuverture', sort: 'desc' }]
                                            : [{ field: 'dateDebut', sort: 'desc' }],
                                    },
                                }}
                                getRowClassName={(params) => {
                                    if (params.row.statut === 'SUPPRIME') return 'deleted-row';
                                    if (params.row.statut === 'Nouveau') return 'new-row'; // Si vous avez une logique pour les nouveaux éléments
                                    return '';
                                }}
                                columnBuffer={5}
                                autoHeight
                                sx={{
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#f5f5f5', // Couleur de l'en-tête des colonnes
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'nowrap', // Empêche le retour à la ligne dans les cellules
                                    },
                                    '& .deleted-row': {
                                        backgroundColor: '#d3d3d3', // Couleur des lignes supprimées
                                        textDecoration: 'line-through', // Ligne barrée pour indiquer la suppression
                                        pointerEvents: 'none', // Désactive les interactions avec les lignes supprimées
                                        opacity: 0.5, // Rendre la ligne plus pâle pour indiquer qu'elle est supprimée
                                    },
                                    '& .new-row': {
                                        backgroundColor: '#e0ffe0', // Couleur des lignes nouvellement créées
                                    },
                                }}
                            />
                        </div>
                    )}
                </Box>
                <Box
                    sx={{
                        textAlign: 'right',
                        padding: 2,
                        backgroundColor: '#f5f5f5',
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Lou-Ann Technologies © 2024 - v1.0.0
                    </Typography>
                </Box>
            </Box>

            {/* Snackbar pour les notifications */}
            <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={() => setOpenAlert(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    onClose={() => setOpenAlert(false)}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>

            {/* Sidebar pour afficher et modifier les informations du ticket */}
            <Drawer
                anchor="right"
                open={isSidebarOpen}
                onClose={handleCloseSidebar}
                PaperProps={{
                    sx: { width: 400, padding: 2 },
                }}
            >
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Détails du Ticket</Typography>
                        <IconButton onClick={handleCloseSidebar}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {editedTicket ? (
                        <Box mt={2}>
                            {/* Champ Titre : Modifiable pour le client si statut "EN_ATTENTE", ou modifiable pour l'admin */}
                            <TextField
                                label="Titre"
                                name="titre"
                                value={editedTicket.titre}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                disabled={
                                    role === 'ROLE_CLIENT' && editedTicket.statut !== 'EN_ATTENTE'
                                } // Le client peut modifier uniquement si statut "EN_ATTENTE", l'admin toujours
                            />

                            {/* Champ Description : Modifiable pour le client si statut "EN_ATTENTE", ou modifiable pour l'admin */}
                            <TextField
                                label="Description"
                                name="description"
                                value={editedTicket.description}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                                disabled={
                                    role === 'ROLE_CLIENT' && editedTicket.statut !== 'EN_ATTENTE'
                                } // Le client peut modifier uniquement si statut "EN_ATTENTE", l'admin toujours
                            />

                            {/* Champ Statut : Toujours modifiable pour l'admin, jamais pour le client */}
                            <FormControl
                                fullWidth
                                margin="normal"
                                disabled={role === 'ROLE_CLIENT'} // Toujours désactivé pour les clients
                            >
                                <InputLabel>Statut</InputLabel>
                                <Select
                                    label="Statut"
                                    name="statut"
                                    value={editedTicket.statut}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="EN_ATTENTE">En attente</MenuItem>
                                    <MenuItem value="PRIS_EN_COMPTE">Pris en compte</MenuItem>
                                    <MenuItem value="EN_COURS">En cours</MenuItem>
                                    <MenuItem value="TRAITE">Traité</MenuItem>
                                    <MenuItem value="CLOTURE">Clôturé</MenuItem>
                                    <MenuItem value="SUPPRIME">Supprimé</MenuItem>
                                </Select>
                            </FormControl>

                            {/* Bouton Sauvegarder : Actif seulement si le client peut modifier ou si c'est un admin */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveChanges}
                                fullWidth
                                sx={{ mt: 2 }}
                                disabled={
                                    role === 'ROLE_CLIENT' && editedTicket.statut !== 'EN_ATTENTE'
                                } // Le client peut sauvegarder uniquement si statut "EN_ATTENTE", l'admin toujours
                            >
                                Sauvegarder les modifications
                            </Button>
                        </Box>
                    ) : (
                        <Typography variant="body1">Aucun ticket sélectionné.</Typography>
                    )}
                </Box>
            </Drawer>
        </Box>
    );
};

export default UserDashboard;
