import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Box, Typography, Paper, Stack } from '@mui/material';
import axios from 'axios';
import config from './config';

const SetPassword = () => {
    const [motDePasse, setMotDePasse] = useState('');  // Change "password" en "motDePasse"
    const [confirmMotDePasse, setConfirmMotDePasse] = useState('');  // Change également pour "confirmMotDePasse"
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Extraire le token de l'URL
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (motDePasse !== confirmMotDePasse) {
            setError("Les mots de passe ne correspondent pas.");
            return;
        }

        try {
            const response = await axios.post(`${config.apiUrl}/api/client/set-password`, {
                token: token,
                motDePasse: motDePasse
            }, {
                headers: {
                    'Content-Type': 'application/json'  // Assure-toi que seul ce header est utilisé
                }
            });

            if (response.status === 200) {
                setSuccess(true);
                setTimeout(() => navigate('/'), 3000);  // Rediriger vers la page de connexion après 3 secondes
            }
        } catch (error) {
            setError("Erreur lors de la définition du mot de passe. Veuillez vérifier le lien ou réessayer.");
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 400, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Définir votre mot de passe
                </Typography>
                {error && <Typography variant="body1" color="error">{error}</Typography>}
                {success && <Typography variant="body1" color="success">Mot de passe défini avec succès. Vous serez redirigé vers la page de connexion.</Typography>}

                <TextField
                    label="Nouveau mot de passe"
                    value={motDePasse}
                    onChange={(e) => setMotDePasse(e.target.value)}
                    type="password"
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Confirmer le mot de passe"
                    value={confirmMotDePasse}
                    onChange={(e) => setConfirmMotDePasse(e.target.value)}
                    type="password"
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button type="submit" variant="contained" color="primary">
                        Définir le mot de passe
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default SetPassword;
