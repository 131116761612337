import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ReportModal = ({ open, handleClose, onSubmit }) => {
    const [report, setReport] = useState('');

    const handleSubmit = () => {
        if (report.trim()) {
            onSubmit(report); // Appel de la fonction de soumission avec le contenu du rapport
            setReport(''); // Réinitialise le champ du rapport
            handleClose(); // Ferme le modal
        } else {
            alert('Le rapport est requis.');
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="report-modal">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2">
                        Ajouter un rapport de résolution
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <TextField
                    fullWidth
                    label="Rapport"
                    multiline
                    rows={4}
                    value={report}
                    onChange={(e) => setReport(e.target.value)}
                    sx={{ mt: 2 }}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSubmit}
                >
                    Soumettre
                </Button>
            </Box>
        </Modal>
    );
};

export default ReportModal;
